import { useNextRouter } from '~/libs/hooks/useNextRouter'
import React, { useEffect } from 'react'
import { useGetUserConfiguration } from '~/libs/apis/service/api'
import useAuth from '~/libs/hooks/useAuth'
import { useRouter } from 'next/router'

const Redirect = () => {
  const { isLoggedIn } = useAuth()

  const { isLoading } = useGetUserConfiguration({
    swr: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
    request: {
      skipNewVersionCheck: true,
    },
  })
  const router = useRouter()
  const localeRouter = useNextRouter({ suspense: false })

  useEffect(() => {
    if (!isLoggedIn()) {
      router.replace('/login')
      return
    }
    if (!isLoading) {
      localeRouter.replace(localeRouter.asPath)
    }
  }, [router, localeRouter, isLoading, isLoggedIn])

  return null
}

Redirect.getLayout = (page: React.JSX.Element) => {
  return page
}

export default Redirect
